<template>
  <div class="container mx-auto main-wrapper">
    <header>
      <h1 class="h1-title">Search Works</h1>
    </header>

    <div>
      <ais-instant-search
        :search-client="searchClient"
        :index-name="indexName"
        :routing="routing"
      >
        <ais-configure :filters="yearFilter" />
        <div class="searchbox">
          <ais-search-box
            placeholder="search by keyword, title, pigment, etc."
          />
        </div>
        <div class="results-stat-container">
          <ais-stats />
        </div>

        <hr class="my-2 mb-4" />

        <div class="under-search-container">
          <div class="search-panel">
            <CollapsiblePanel
              label="ADVANCED SEARCH"
              :initialVisible="initialVisible"
              @visibilityChanged="onAdvancedSearchVisibilityChanged"
            >
              <div class="search-section-title">Work Facets</div>

              <ais-toggle-refinement
                class="slider-button"
                attribute="has_image"
                label="Has Image"
              />

              <CollapsiblePanel label="Century">
                <ais-panel>
                  <ais-refinement-list
                    searchable
                    show-more
                    :show-more-limit="showMoreLimit"
                    attribute="centuries"
                    :sort-by="sortCenturies"
                  />
                </ais-panel>
              </CollapsiblePanel>

              <CollapsiblePanel label="Repository">
                <ais-panel>
                  <ais-refinement-list
                    searchable
                    show-more
                    :show-more-limit="showMoreLimit"
                    attribute="repository"
                  />
                </ais-panel>
              </CollapsiblePanel>

              <CollapsiblePanel label="Location">
                <ais-panel>
                  <ais-refinement-list
                    searchable
                    show-more
                    :show-more-limit="showMoreLimit"
                    attribute="location"
                  />
                </ais-panel>
              </CollapsiblePanel>

              <CollapsiblePanel label="Classification">
                <ais-panel>
                  <ais-refinement-list
                    show-more
                    :show-more-limit="showMoreLimit"
                    attribute="classification"
                  />
                </ais-panel>
              </CollapsiblePanel>

              <CollapsiblePanel label="Medium">
                <ais-panel>
                  <ais-refinement-list
                    show-more
                    :show-more-limit="showMoreLimit"
                    attribute="medium"
                  />
                </ais-panel>
              </CollapsiblePanel>

              <CollapsiblePanel label="Artist">
                <ais-panel>
                  <ais-refinement-list
                    searchable
                    show-more
                    :show-more-limit="showMoreLimit"
                    attribute="artist"
                  />
                </ais-panel>
              </CollapsiblePanel>

              <!-- <CollapsiblePanel label="Last Updated">
                  <ais-panel>
                    if the min and max are not specified, it defaults to 0 and I can't modify the facet in the UX
                      it looks like the component is not getting the range properly from the search state
                      the custom slot is also not working with the end side of the input
                      The state.start attribute is an array, and state.start[0] will update but state.start[1] stays at Infinity
                      Same with state.instantSearchInstance.helper.state.range.numericRefinements.lastUpdated: the lastUpdated[">="][0]= the min,
                      but there is no lastUpdated["<="][1] for the max
                      see: https://www.algolia.com/doc/api-reference/widgets/range-input/vue/#customize-the-ui
                    <ais-range-input
                      attribute="last_updated"
                    >
                      <template
                        v-slot="{
                          currentRefinement,
                          range,
                          canRefine,
                          refine,
                          sendEvent,
                        }"
                      >
                        <input
                          type="number"
                          :min=2018
                          :max=2025
                          :placeholder=2018
                          :value="formatMinValue(currentRefinement.min, 2018)"
                          @input="refine({
                            min:$event.currentTarget.value,
                            max: formatMaxValue(currentRefinement.max, 2025),
                          })"
                        >
                        →
                        <input
                          type="number"
                          :min=2018
                          :max=2025
                          :placeholder=2025
                          :value="formatMaxValue(currentRefinement.max,2025)"
                          @input="refine({
                            min:formatMinValue(currentRefinement.min, 2018) || 2018,
                            max: $event.currentTarget.value ? parseInt($event.currentTarget.value, 10) : 2025,
                          })"
                        >
                      </template>
  </ais-range-input>
  </ais-panel>
  </CollapsiblePanel> -->

              <div class="search-section-title">Analysis Facets</div>

              <ais-toggle-refinement
                class="slider-button"
                attribute="has_published_analysis"
                label="Has Published Analysis"
              />

              <CollapsiblePanel label="Pigments">
                <ais-panel>
                  <ais-refinement-list
                    searchable
                    show-more
                    :show-more-limit="showMoreLimit"
                    attribute="pigments"
                  />
                </ais-panel>
              </CollapsiblePanel>

              <CollapsiblePanel label="Elements">
                <ais-panel>
                  <ais-refinement-list
                    show-more
                    :show-more-limit="showMoreLimit"
                    attribute="elements"
                  />
                </ais-panel>
              </CollapsiblePanel>

              <CollapsiblePanel label="Methodology">
                <ais-panel>
                  <ais-refinement-list
                    show-more
                    :show-more-limit="showMoreLimit"
                    attribute="methodologies"
                  />
                </ais-panel>
              </CollapsiblePanel>

              <CollapsiblePanel label="Colors">
                <ais-panel>
                  <ais-refinement-list
                    show-more
                    :show-more-limit="showMoreLimit"
                    attribute="colors"
                  />
                </ais-panel>
              </CollapsiblePanel>

              <CollapsiblePanel label="Analysts">
                <ais-panel>
                  <ais-refinement-list
                    searchable
                    :show-more-limit="showMoreLimit"
                    show-more
                    attribute="analysts"
                  />
                </ais-panel>
              </CollapsiblePanel>
            </CollapsiblePanel>
          </div>

          <div
            class="filter-and-results"
            :class="{ 'full-width': !isAdvancedSearchVisible }"
          >
            <div class="main-filter-container">
              <div class="search-filters-top-container">
                <button class="search-filter-button" @click="toggleView">
                  Change to <span v-if="view == 'thumb'">map</span
                  ><span v-else>thumbnail</span> view
                </button>

                <ais-hits-per-page :items="hitsPerPageItems" />

                <ais-sort-by :items="sortByItems" />

                <ais-clear-refinements>
                  <template v-slot="{ refine }">
                    <button
                      @click="onClearRefinementsClick(refine)"
                      class="ais-ClearRefinements-button"
                    >
                      Clear Search
                    </button>
                  </template>
                </ais-clear-refinements>
              </div>

              <div class="search-filters-date-container">
                <ais-panel>
                  <!--
                  previously labeled "Creation Date"; may eventually update that in other places
                  uses the simple WorkDate component that just gets the date changes and emits them
                  trying to then use the emitted range with logic in this component to refine the search with a filter
                -->
                  <WorkDate
                    :defaultStartYear="start_year"
                    :defaultEndYear="end_year"
                    @yearRangeChanged="handleYearChange"
                  />
                  <div class="date-label-container">
                    <p class="filter-date-label">500</p>
                    <p class="filter-date-label">2000</p>
                  </div>
                </ais-panel>
              </div>

              <div class="search-filters-refinement-container">
                <ais-current-refinements :transform-items="transformItems">
                </ais-current-refinements>
              </div>
            </div>

            <div
              class="search-panel__results"
              :class="{ 'full-width': !isAdvancedSearchVisible }"
            >
              <ais-hits>
                <template v-slot="{ items }">
                  <ThumbnailResults v-if="view === 'thumb'" :results="items" />
                  <MapResults v-else :results="items" />
                </template>
              </ais-hits>

              <div class="pagination" v-if="view === 'thumb'">
                <ais-pagination />
              </div>
            </div>
          </div>
        </div>
      </ais-instant-search>
    </div>
  </div>
</template>

<script>
import Client from '@searchkit/instantsearch-client';
import Searchkit from 'searchkit';
import { history } from 'instantsearch.js/es/lib/routers';
// import { simple } from 'instantsearch.js/es/lib/stateMappings';

import ThumbnailResults from '../components/search-results/ThumbnailResults.vue';
import MapResults from '../components/search-results/MapResults.vue';
import WorkDate from '../components/searchkit/WorkDate.vue';
import CollapsiblePanel from '../components/CollapsiblePanel.vue';
import { inject } from 'vue';
import { sortCenturies } from '@public/js/utils/sortCenturies';
import getCSRFToken from '@public/js/utils/getCSRFToken';

let host;
if (window.envUrl === 'use_origin') {
  host = window.location.origin;
} else {
  host = window.envUrl;
}

const csrfToken = getCSRFToken();

const config = {
  connection: {
    host: `${host}/es_search/searchkit`,
    headers: {
      'X-CSRFToken': csrfToken, // Include CSRF token in the request headers
    },
  },
  search_settings: {
    // custom search handled below
    search_attributes: [
      { field: 'title', weight: 3 },
      'repository.name',
      'text',
      'paratext',
      'classification.name', // type
      'artist.name',
      'mediums.name',
      'location.name',
      'location.description',
      'pigments.name',
      'pigments.alternate_spellings',
      'pigments.chemical_composition',
      'elements.symbol',
      'elements.name',
      'analysts.name',
      'colors.name',
      'methodologies.name',
      'methodologies.acronym',
      'parent_work.name',
      'vcam_descriptions',
    ],
    result_attributes: [
      'title',
      'repository',
      'text',
      'paratext',
      'accession_number',
      'classification',
      'location',
      'creation_date',
      'slug',
      'has_published_analysis',
      'has_image',
      'last_updated',
      'creation_lower_fuzzy_year',
      'creation_upper_fuzzy_year',
      'centuries',
      'vcam_descriptions',
      'image',
      'published_analyses_count',
    ],
    facet_attributes: [
      {
        attribute: 'repository',
        field: 'repository.name.keyword',
        type: 'string',
      },
      {
        attribute: 'location',
        field: 'location.name.keyword',
        type: 'string',
      },
      {
        attribute: 'classification',
        field: 'classification.name.keyword',
        type: 'string',
      },
      {
        attribute: 'medium',
        field: 'mediums.name.keyword',
        type: 'string',
      },
      {
        attribute: 'artist',
        field: 'artist.name.keyword',
        type: 'string',
      },
      {
        attribute: 'pigments',
        field: 'pigments.name.keyword',
        type: 'string',
      },
      {
        attribute: 'methodologies',
        field: 'methodologies.name.keyword',
        type: 'string',
      },
      {
        attribute: 'colors',
        field: 'colors.name.keyword',
        type: 'string',
      },
      {
        attribute: 'elements',
        field: 'elements.symbol.keyword',
        type: 'string',
      },
      {
        attribute: 'analysts',
        field: 'analysts.name.keyword',
        type: 'string',
      },
      {
        attribute: 'has_published_analysis',
        field: 'has_published_analysis',
        type: 'boolean',
      },
      {
        attribute: 'has_image',
        field: 'has_image',
        type: 'boolean',
      },
      {
        attribute: 'last_updated',
        field: 'last_updated',
        type: 'number',
      },
      {
        attribute: 'creation_lower_fuzzy_year',
        field: 'creation_lower_fuzzy_year',
        type: 'date',
      },
      {
        attribute: 'creation_upper_fuzzy_year',
        field: 'creation_upper_fuzzy_year',
        type: 'date',
      },
      {
        attribute: 'centuries',
        field: 'centuries.name.keyword',
        type: 'string',
      },
    ],
    sorting: {
      default: {
        field: '_score',
        order: 'desc',
      },
      _title_asc: {
        field: 'title.keyword',
        order: 'asc',
      },
      _title_desc: {
        field: 'title.keyword',
        order: 'desc',
      },
      _last_updated_asc: {
        field: 'last_updated',
        order: 'asc',
      },
      _last_updated_desc: {
        field: 'last_updated',
        order: 'desc',
      },
      _creation_date_asc: {
        field: 'creation_lower_fuzzy_year',
        order: 'asc',
      },
      _creation_date_desc: {
        field: 'creation_lower_fuzzy_year',
        order: 'desc',
      },
    },
    snippet_attributes: [],
    query_rules: [],
  },
};

const searchkitClient = new Searchkit(config, { debug: true });
const searchClient = Client(searchkitClient, {
  hooks: {
    afterSearch: (searchRequests, searchResponses) => {
      const uiRequest = searchRequests[0];
      const userQuery = uiRequest.request.params.query;
      const mainResponse = searchResponses[0];
      const hits = mainResponse.hits.hits.slice(0, 50); // Consider only the first 50 hits
      if (hits.length > 0 && userQuery) {
        // Filter hits to include only those with accession_number matching or starting with userQuery
        const filteredHits = hits.filter((hit) => {
          const accessionNumber = hit._source.accession_number;
          // handle null accession_number
          if (!accessionNumber) {
            return false;
          }
          return (
            accessionNumber === userQuery ||
            accessionNumber.startsWith(userQuery)
          );
        });
        // If there are any hits that match, replace the hits in the response
        if (filteredHits.length > 0) {
          mainResponse.hits.hits = filteredHits;
          mainResponse.hits.total.value = filteredHits.length;
          // update the searchResponses with the filtered hits and return
          searchResponses[0] = mainResponse;
        }
      }
      return searchResponses;
    },
  },
  getQuery: (query) => {
    const baseQuery = {
      bool: {
        should: [
          // Boosted exact and prefix matches on accession_number
          {
            term: {
              'accession_number.keyword': {
                value: query,
                boost: 3,
              },
            },
          },
          {
            prefix: {
              'accession_number.keyword': {
                value: query,
                boost: 3,
              },
            },
          },
          // simple_query_string query for more advanced (but sanitized) searches
          {
            simple_query_string: {
              query: query,
              fields: [
                'title^3',
                'accession_number^3',
                'repository.name',
                'text',
                'paratext',
                'classification.name',
                'artist.name',
                'mediums.name',
                'location.name',
                'location.description',
                'pigments.name',
                'pigments.alternate_spellings',
                'pigments.chemical_composition',
                'elements.symbol',
                'elements.name',
                'analysts.name',
                'colors.name',
                'methodologies.name',
                'methodologies.acronym',
                'parent_work.name',
                'vcam_descriptions',
              ],
              default_operator: 'OR', // Require all terms unless overridden in query
            },
          },
        ],
        minimum_should_match: 1,
      },
    };

    return baseQuery;
  },
});

const indexName = window.globalIndexName;

// Custom state mapping helper functions
function parseYearFilter(filterString) {
  // the filter looks like this: `creation_lower_fuzzy_year:[2020 TO *] AND creation_upper_fuzzy_year:[* TO 2021]`
  const startYear = filterString.match(
    /creation_lower_fuzzy_year:\[(\d{4})/,
  )[1];
  const endYear = filterString.match(
    /creation_upper_fuzzy_year:\[\*\sTO\s(\d{4})/,
  )[1];
  return [startYear, endYear];
}

export default {
  components: {
    ThumbnailResults,
    MapResults,
    CollapsiblePanel,
    WorkDate,
  },
  setup() {
    const updateCiteType = inject('updateCiteType');
    updateCiteType('none');
  },
  data() {
    const vueRouter = this.$router;
    return {
      searchClient,
      view: 'thumb',
      isNarrowScreen: window.innerWidth < 900,
      isAdvancedSearchVisible: this.calculateInitialVisibility(),
      currentHitsPerPage: 20,
      start_year: null,
      end_year: null,
      // last_updated_start: null,
      // last_updated_end: null
      indexName: indexName,
      showMoreLimit: 1000,
      routing: {
        router: history(),
        stateMapping: {
          stateToRoute(uiState) {
            const indexUiState = uiState[indexName] || {};
            let routeState = {};

            // Handle standard InstantSearch parameters directly
            if (indexUiState.query) {
              routeState[`works[query]`] = indexUiState.query;
            }
            if (indexUiState.refinementList) {
              routeState[`works[refinementList]`] = indexUiState.refinementList;
            }
            if (indexUiState.sortBy) {
              routeState[`works[sortBy]`] = indexUiState.sortBy;
            }
            if (indexUiState.hitsPerPage) {
              routeState[`works[hitsPerPage]`] = indexUiState.hitsPerPage;
            }
            if (indexUiState.toggle) {
              routeState[`works[toggle]`] = indexUiState.toggle;
            }

            // Add custom date filters
            if (
              indexUiState.configure &&
              indexUiState.configure.filters &&
              indexUiState.configure.filters.includes('creation')
            ) {
              const [start_year, end_year] = parseYearFilter(
                indexUiState.configure.filters,
              );
              routeState['start_year'] = start_year;
              routeState['end_year'] = end_year;
            }
            // update the router state in vue
            vueRouter.replace({ query: routeState });
            return routeState;
          },
          routeToState(routeState) {
            const uiState = {};

            // Check if the works namespace exists in the routeState
            if (routeState[`works`]) {
              uiState[`${indexName}`] = {}; // Initialize the works namespace in uiState

              // Reconstruct query
              if (routeState[`works`].query) {
                uiState[`${indexName}`].query = routeState[`works`].query;
              }

              // Reconstruct hitsPerPage
              if (routeState[`works`].hitsPerPage) {
                uiState[`${indexName}`].hitsPerPage = parseInt(
                  routeState[`works`].hitsPerPage,
                  10,
                );
              }

              // Reconstruct sortBy
              if (routeState[`works`].sortBy) {
                uiState[`${indexName}`].sortBy = routeState[`works`].sortBy;
              }

              // Reconstruct refinementList
              if (routeState[`works`].refinementList) {
                uiState[`${indexName}`].refinementList = {};

                Object.keys(routeState[`works`].refinementList).forEach(
                  (facet) => {
                    uiState[`${indexName}`].refinementList[facet] =
                      routeState[`works`].refinementList[facet];
                  },
                );
              }

              // Reconstruct toggle
              if (routeState[`works`].toggle) {
                uiState[`${indexName}`].toggle = routeState[`works`].toggle;
              }

              // Custom date filters
              if (
                uiState.configure &&
                uiState.configure.filters &&
                uiState.configure.filters.includes('creation')
              ) {
                const [start_year, end_year] = parseYearFilter(
                  uiState.configure.filters,
                );
                routeState['start_year'] = start_year;
                routeState['end_year'] = end_year;
              }
            }

            return uiState;
          },
        },
      },
    };
  },
  methods: {
    calculateInitialVisibility() {
      return !this.isNarrowScreen;
    },
    checkWidth() {
      this.isNarrowScreen = window.innerWidth < 900;
    },
    onAdvancedSearchVisibilityChanged(isVisible) {
      this.isAdvancedSearchVisible = isVisible;
    },
    toggleView() {
      this.view = this.view === 'thumb' ? 'map' : 'thumb';
      if (this.view === 'map') {
        // show all the pins with ais-hits-per-page
        this.currentHitsPerPage = 10000;
      } else {
        // put the show count back to the default
        this.currentHitsPerPage = 20;
      }
    },
    // listen to the yearRangeChanged emitted event from the WorkDate component
    handleYearChange(data) {
      this.start_year = parseInt(data.startYear, 10);
      this.end_year = parseInt(data.endYear, 10);
    },
    // handle clearing refinements customization
    handleClearDateFilter() {
      this.start_year = null;
      this.end_year = null;
    },
    // for the customized ais-clear-refinements component
    onClearRefinementsClick(refine) {
      // Call the original refine function to clear all refinements
      refine();

      // Additionally, reset the date filter
      this.handleClearDateFilter();
    },
    // Customizing the slot for ais-current-refinements because of the WorkDate component
    transformItems(items) {
      if (this.start_year && this.end_year) {
        const customDateFilterItem = {
          attribute: 'customDateFilter',
          label: 'Date Range',
          refinements: [
            {
              type: 'custom',
              attribute: 'customDateFilter',
              label: `From ${this.start_year} to ${this.end_year}`,
              value: { start_year: this.start_year, end_year: this.end_year },
            },
          ],
          // The refine function is called when the user clicks the remove button
          refine: () => {
            this.handleClearDateFilter();
          },
        };

        // Here, we're adding the custom date filter to the list of current refinements
        items.push(customDateFilterItem);
      }

      return items;
    },
    sortCenturies,
    // For last_updated filter:
    // formatMinValue(minValue, minRange) {
    //   if (minValue === null || minValue < minRange) {
    //     return minRange;
    //   } else {
    //     return minValue;
    //   }
    // },
    // formatMaxValue(maxValue, maxRange) {
    //   if (maxValue === null || maxValue > maxRange) {
    //     return maxRange;
    //   } else {
    //     return maxValue;
    //   }
    // },
  },
  computed: {
    yearFilter() {
      // should filter with this.start_year less TO *, and * to this.end_year
      // If both are present:
      if (this.start_year && this.end_year) {
        return `creation_lower_fuzzy_year:[${this.start_year} TO *] AND creation_upper_fuzzy_year:[* TO ${this.end_year}]`;
      } else if (this.start_year) {
        return `creation_lower_fuzzy_year:[${this.start_year} TO *]`;
      } else if (this.end_year) {
        return `creation_upper_fuzzy_year:[* TO ${this.end_year}]`;
      } else {
        // If neither are present
        return '';
      }
    },
    hitsPerPageItems() {
      if (this.view === 'map') {
        return [
          { value: 10, label: '10 per page' },
          { value: 20, label: '20 per page' },
          { value: 50, label: '50 per page' },
          { value: 10000, label: 'Show all', default: true },
        ];
      } else {
        return [
          { value: 10, label: '10 per page' },
          { value: 20, label: '20 per page', default: true },
          { value: 50, label: '50 per page' },
          { value: 10000, label: 'Show all' },
        ];
      }
    },
    sortByItems() {
      return [
        { value: `${this.indexName}`, label: 'Relevance' },
        { value: `${this.indexName}_title_asc`, label: 'Title (asc)' },
        { value: `${this.indexName}_title_desc`, label: 'Title (desc)' },
        {
          value: `${this.indexName}_last_updated_asc`,
          label: 'Last Updated (asc)',
        },
        {
          value: `${this.indexName}_last_updated_desc`,
          label: 'Last Updated (desc)',
        },
        {
          value: `${this.indexName}_creation_date_asc`,
          label: 'Object Date (asc)',
        },
        {
          value: `${this.indexName}_creation_date_desc`,
          label: 'Object Date (desc)',
        },
      ];
    },
    isMobile() {
      return this.isNarrowScreen;
    },
    initialVisible() {
      return !this.isNarrowScreen;
    },
  },
  created() {
    window.addEventListener('resize', this.checkWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkWidth);
  },
  watch: {
    // Watch the $route object for changes
    '$route.query': {
      handler(query) {
        // Extract and update start_year and end_year based on the route's query parameters
        if (query.start_year && query.end_year) {
          this.start_year = query.start_year;
          this.end_year = query.end_year;
        }
        // extract and update view based on the route's query parameters
        if (query.view) {
          this.view = query.view;
        }
      },
      immediate: true, // This ensures the watcher is triggered on initial load as well
    },
  },
};
</script>

<style>
@import 'https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/satellite-min.css';

.searchbox {
  margin-bottom: 20px;
}

.search-panel {
  width: 20%;
  float: left;
}

.search-panel__results {
  width: 75%;
  float: right;
}

.ais-Panel {
  font-family: Agrandir, ui-sans-serif, system-ui;
}

/* for some reason this is always displaying 0, just hide it */
.ais-ToggleRefinement-count {
  display: none;
}

.search-panel__results.full-width {
  width: 100%;
  margin-left: 0%;
}
</style>
